import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conversationDrawerOpen: false,
  chatDrawerOpen: false,
  selectedConversation: {},
  groupChatDrawerOpen: false,
  groupDetailsDrawerOpen: false,
  selectedGroup: {},
  supportChatPopupOpen: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setConversationDrawerOpen: (state, action) => {
      state.conversationDrawerOpen = action.payload;
    },
    setChatDrawerOpen: (state, action) => {
      state.chatDrawerOpen = action.payload;
    },

    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },

    toggleGroupChatDrawer: (state, action) => {
      const { open, group } = action.payload;
      state.groupChatDrawerOpen = open;
      state.selectedGroup = group;
    },

    setGroupDetailsDrawerOpen: (state, action) => {
      state.groupDetailsDrawerOpen = action.payload;
    },

    setSupportChatPopupOpen: (state, action) => {
      state.supportChatPopupOpen = action.payload;
    },

  },
 
});

export const {
  setConversationDrawerOpen,
  setChatDrawerOpen,
  setSelectedConversation,
  toggleGroupChatDrawer,
  setGroupDetailsDrawerOpen,
  setSupportChatPopupOpen,
} = chatSlice.actions;
export default chatSlice.reducer;
