import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  documentsLoading: [],
  documentsList: [],
};


const documentsSlice = createSlice({
  name: "documentState",
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },

  },
  extraReducers: () => {

  },
});

export const { setQuery} = documentsSlice.actions;
export default documentsSlice.reducer;
