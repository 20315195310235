/* eslint-disable no-undef */

import axios from "axios";
import qs from "qs";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  paramsSerializer: (params) => {
    let paramsToPass = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== null && params[key] !== undefined) {
      acc[key] = params[key];
      }
      return acc;
    }, {});    
    
    return qs.stringify(paramsToPass, { arrayFormat: "repeat" })
  },
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
