import React, { lazy } from "react";
import NotFound from "./pages/NotFound";
import { ENTRY_ROUTE } from "./common/constants";
import { Routes, Route, Navigate } from "react-router-dom";
import RSS from "pages/app/rss";
const MainLayout = lazy(() => import("./components/layouts/MainLayout"));
const PublicLayout = lazy(() => import("./components/layouts/PublicLayout"));
const Divisions = lazy(() => import("pages/app/divisions/Divisions"));
const WelcomeTwo = lazy(() => import("pages/app/WelcomeTwo"));
const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));
const Mfa = lazy(() => import("./pages/auth/Mfa"));
const Admin = lazy(() => import("./pages/app/admin"));
const AddUser = lazy(() => import("./pages/app/admin/users/AddUser"));
const ChangePassword = lazy(() =>
import("./pages/app/admin/users/ChangePassword")
);
const EditUser = lazy(() => import("./pages/app/admin/users/EditUser"));
const AddClient = lazy(() => import("./pages/app/admin/clients/AddClient"));
const EditClient = lazy(() => import("./pages/app/admin/clients/EditClient"));
const Tasks = lazy(() => import("./pages/app/tasks/Tasks"));
const Templates = lazy(() => import("./pages/app/task_templates/Templates"));
const Documents = lazy(() => import("./pages/app/documents/Documents"));
const Chat = lazy(() => import("./pages/app/chat/Chat"));
const Entities = lazy(() => import("pages/app/entities/Entities"));
const ReferencePointers = lazy(() =>
  import("pages/app/reference_pointers/ReferencePointers")
);

const RoutesConfig = () => {


  return (
    <>
    
      <Routes>
        {/* Public Routes */}
        <Route path="" element={<Navigate to={ENTRY_ROUTE} />} />
        <Route path="/auth" element={<PublicLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="mfa" element={<Mfa />} />
          <Route path="register" element={<Register />} />
        </Route>

        {/* Protected Routes */}
        <Route path="/" element={<MainLayout />}>
          <Route path="*" element={<NotFound />} />
          <Route path="admin" element={<Admin />} />
          <Route path="news-feed" element={<RSS />} />
          <Route path="users">
            <Route path="add" element={<AddUser />} />
            <Route path="edit/:id" element={<EditUser />} />
            <Route path="change-password/:id" element={<ChangePassword />} />
          </Route>

          <Route path="chat">
            <Route path="" element={<Chat />} />
          </Route>
          <Route>
            <Route path="/entities" element={<Entities />} />
          </Route>
          <Route>
            <Route path="/divisions" element={<Divisions />} />
          </Route>

          <Route path="clients">
            <Route path="add" element={<AddClient />} />
            <Route path="edit/:id" element={<EditClient />} />
          </Route>

          <Route path="tasks">
            <Route path="" element={<Tasks />} />
          </Route>

          <Route path="templates">
            <Route path="" element={<Templates />} />
          </Route>

          <Route path="documents">
            <Route path="" element={<Documents />} />
          </Route>

          <Route path="reference-pointers">
            <Route path="" element={<ReferencePointers />} />
          </Route>

          <Route path="dashboard" element={<WelcomeTwo />} />

        </Route>
      </Routes>
      {/* } */}
    </>
  );
};

export default RoutesConfig;
