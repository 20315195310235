/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectTemplates : [],
  templatesList: [],
  itemsList: [],
  collegue_names: [],
  groups_tree_list: [],
  funds_tree_list: [],
  selectedTask: null,
  editTask: null,
  drawerOpen: false,
  singleLoading: false,
  listLoading: false,
  formMode: "add",
  formModalOpen: false,
  formSchema: {},
  createTaskTemplateFormSchema: {},
  editTaskTemplateFormSchema: {},
  entityTreeDrawerOpen: false,
  filtersSidebarCollapsed: false,
  entityTreeViewList: [],
  documentsList: [],

  documentVersionDrawerOpen: false,
  selectedDocumentForVersions: null,

  documentViewModalOpen: false,
  selectedDocumentForView: null,

  document_versions: [],
};


const templatesSlice = createSlice({
  name: "templatesState",
  initialState,
  reducers: {
    setSelectedTemplates : (state, action)=>{
      state.selectTemplates = action.payload;
    },
    setTemplatesList: (state, action) => {
      state.templatesList = action.payload;
    },
    setCollegueList: (state, action) => {
      state.collegue_names = action.payload;
    },
    setGroupsTreeList: (state, action) => {
      state.groups_tree_list = action.payload;
    },
    setFundsTreeList: (state, action) => {
      state.funds_tree_list = action.payload;
    },
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setSelectedTask: (state, action) => {
      state.selectedTask = action.payload;
    },
    setSingleLoading: (state, action) => {
      state.singleLoading = action.payload;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setFormMode: (state, action) => {
      state.formMode = action.payload;
    },
    setFormModalOpen: (state, action) => {
      state.formModalOpen = action.payload;
    },
    handleFormModalCancel: (state, action) => {
      state.selectedTask = null;
      state.formMode = "add";
      state.formModalOpen = false;
    },
    handleFormModalCreate: (state, action) => {
      state.formMode = "add";
      state.formModalOpen = true;
    },
    handleFormModalEdit: (state, action) => {
      state.selectedTask = action.payload;
      state.formMode = "edit";
      state.formModalOpen = true;
    },
    handleDrawerClose: (state, action) => {
      state.selectedTask = null;
      state.documentsList = [];
      state.drawerOpen = false;
    },
    setFormSchema: (state, action) => {
      state.formSchema = action.payload;
    },
    setEditTask: (state, action) => {
      state.editTask = action.payload;
    },
    setEntityTreeDrawerOpen: (state, action) => {
      state.entityTreeDrawerOpen = action.payload;
    },
    setFiltersSidebarCollapsed: (state, action) => {
      state.filtersSidebarCollapsed = action.payload;
    },
    handleDocumentVersionDrawerOpen: (state, action) => {
      state.documentVersionDrawerOpen = true;
      state.selectedDocumentForVersions = action.payload;
    },
    handleDocumentVersionDrawerClose: (state, action) => {
      state.documentVersionDrawerOpen = false;
      state.selectedDocumentForVersions = null;
    },
    handleDocumentViewModalOpen: (state, action) => {
      state.documentViewModalOpen = true;
      state.selectedDocumentForView = action.payload;
    },
    handleDocumentViewModalClose: (state, action) => {
      state.documentViewModalOpen = false;
      state.selectedDocumentForView = null;
    },
    setDocumentsList: (state, action) => {
      state.documentsList = action.payload;
    },
  },
  extraReducers: (builder) => {
   
  },
});

export const {
  setTemplatesList,
  setCollegueList,
  setGroupsTreeList,
  setFundsTreeList,
  setDrawerOpen,
  setSelectedTask,
  setSingleLoading,
  setListLoading,
  setFormMode,
  setFormModalOpen,
  handleFormModalCancel,
  handleFormModalCreate,
  handleFormModalEdit,
  setFormSchema,
  setEditTask,
  setEntityTreeDrawerOpen,
  setFiltersSidebarCollapsed,
  handleDrawerClose,
  handleDocumentVersionDrawerClose,
  handleDocumentVersionDrawerOpen,
  setDocumentsList,
  handleDocumentViewModalClose,
  handleDocumentViewModalOpen,
  // setSelectedTemplates,
} = templatesSlice.actions;
export default templatesSlice.reducer;
