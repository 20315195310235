/* eslint-disable no-undef */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import api from 'common/api';
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import axios from "axios";
import {
    logOut,
  setRefreshCredentials,
} from "../redux/features/auth/authSlice";


const useAxios = () => {
    const AUTH_TOKEN = "auth";
    const TOKEN_PAYLOAD_KEY = "authorization";
    let { access, refresh, user }  = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        // Add a request interceptor
        const requestInterceptor = api.interceptors.request.use(
            async (config) => {
                if (access) {
                    const user_in_token = jwt_decode(access);
            
                    const isExpired = dayjs.unix(user_in_token.exp).diff(dayjs()) < 1;
            
                    if (!isExpired) {
                      config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + access;
                    } else {
                      try {
                        const refresh_token_data = { refresh: refresh };
                        const refresh_token_config = {
                          headers: {
                            authorization: "Bearer " + access,
                          },
                        };
            
                        const resp = await axios.post(
                          `${process.env.REACT_APP_API_BASE_URL}/auth/refresh`,
                          refresh_token_data,
                          refresh_token_config
                        );
            
                        const { access: new_access, refresh: new_refresh } = resp.data;
            
                        const new_data_for_local_storage = {
                          access: new_access,
                          refresh: new_refresh,
                          user: user,
                        };
            
                        localStorage.setItem(
                          AUTH_TOKEN,
                          JSON.stringify(new_data_for_local_storage)
                        );
                        dispatch(setRefreshCredentials({ ...new_data_for_local_storage }));
                        config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + new_access;
                      } catch (Err) {
                        console.log(Err)
                    
                      }
                    }
                  }
            
                  return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // Add a response interceptor
        const responseInterceptor = api.interceptors.response.use(
            (response) => {

              // const response_json = response.data;
              // if (response_json.success) {
              //  message.success(response_json?.msg || "Success");
              // }else{
              //   message.error(response_json?.msg || "Something went wrong");
              // }
              return response;
            },
            async (error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(logOut()); // Dispatch an action to logout the user
                }
                return Promise.reject(error);
            }
        );

        // Cleanup the interceptors on unmount
        return () => {
            api.interceptors.request.eject(requestInterceptor);
            api.interceptors.response.eject(responseInterceptor);
        };
    }, [access, dispatch]);

    return api;
};

export default useAxios;
