/* eslint-disable no-undef */
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import themeReducer from "./features/theme/themeSlice";
import tasksReducer from "./features/tasks/taskSlice";
import templateSlice from "./features/tasks/templateSlice";
import documentsReducer from "./features/documents/documentsSlice";
import chatSlice from "./features/chat/chatSlice";
import modalsSlice from "./features/modalsSlice";
import drawersSlice from "./features/drawersSlice";
import queryParamsSlice from "./features/queryParamsSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
    tasks: tasksReducer,
    documents: documentsReducer,
    chat: chatSlice,
    drawers : drawersSlice,
    modals: modalsSlice,
    templates: templateSlice,
    queryParams : queryParamsSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.REACT_APP_DEBUG || false,
});
