/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import { notification, message as antdMessage } from "antd";
import React, { createContext, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { setSupportChatPopupOpen } from "redux/features/chat/chatSlice";

const WebSocketContext = createContext(null);

export { WebSocketContext };

export default ({ children }) => {
  
  const [private_socket_connected, setPrivateConnected] = useState(false);
  const [group_socket_connected, setGroupConnected] = useState(false);
  const [admin_socket_connected, setAdminConnected] = useState(false);

  const [messageApi, contextHolder] = antdMessage.useMessage();
  const key = 'updatable';

  const queryClient = useQueryClient();
  const { access, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [socketConnection, setSocketConnection] = useState({
    privateChatSocket: null,
    adminChatSocket: null,
    groupChatSocket: null,
  });

  const sendPrivateMessage = (message_payload) => {
    socketConnection.privateChatSocket.send(JSON.stringify(message_payload));
  };

  const sendMessageAdmin = (message_payload) => {
    socketConnection.adminChatSocket.send(JSON.stringify(message_payload));
  };

  const sendMessageGroup = (message_payload) => {
    socketConnection.groupChatSocket.send(JSON.stringify(message_payload));
  };

  useEffect(() => {
    if (access && user) {
      // Show loading message when starting connection
      messageApi.open({
        key,
        type: 'loading',
        content: 'Connecting...',
      });

      // init the connection
      const newPrivateConnection = new WebSocket(
        `${process.env.REACT_APP_API_BASE_URL.replace(
          "http",
          "ws"
        )}/chat/ws/private_message` + "?token=" + access
      );

      const newAdminConnection = new WebSocket(
        `${process.env.REACT_APP_API_BASE_URL.replace(
          "http",
          "ws"
        )}/chat/ws/admin_message` + "?token=" + access
      );

      const newGroupConnection = new WebSocket(
        `${process.env.REACT_APP_API_BASE_URL.replace(
          "http",
          "ws"
        )}/chat/ws/group_message` + "?token=" + access
      );

      newPrivateConnection.addEventListener("open", () => {
        setPrivateConnected(true);
      });

      newAdminConnection.addEventListener("open", () => {
        setAdminConnected(true);
      });

      newGroupConnection.addEventListener("open", () => {
        setGroupConnected(true);
      });

      setSocketConnection({
        privateChatSocket: newPrivateConnection,
        groupChatSocket: newGroupConnection,
        adminChatSocket: newAdminConnection,
      });
    }
  }, [access,user]);

  useEffect(() => {
    if (private_socket_connected && group_socket_connected && admin_socket_connected) {
      messageApi.open({
        key,
        type: 'success',
        content: 'Connected',
        duration: 1,
      });
    }
  }, [private_socket_connected, group_socket_connected, admin_socket_connected]);

  useEffect(() => {
    // adding DOM listeners
    if (socketConnection.privateChatSocket) {
      socketConnection.privateChatSocket.addEventListener(
        "message",
        privateMessageCallback
      );
    }
    if (socketConnection.adminChatSocket) {
      socketConnection.adminChatSocket.addEventListener(
        "message",
        adminMessageCallback
      );
    }
    if (socketConnection.groupChatSocket) {
      socketConnection.groupChatSocket.addEventListener(
        "message",
        groupMessageCallback
      );
    }

    return () => {
      if (socketConnection.privateChatSocket) {
        socketConnection.privateChatSocket.removeEventListener(
          "message",
          privateMessageCallback
        );
        socketConnection.privateChatSocket.removeEventListener("open", () => {
          setPrivateConnected(false);
        });
      }
      if (socketConnection.adminChatSocket) {
        socketConnection.adminChatSocket.removeEventListener(
          "message",
          adminMessageCallback
        );
        socketConnection.adminChatSocket.removeEventListener("open", () => {
          setAdminConnected(false);
        });
      }
      if (socketConnection.groupChatSocket) {
        socketConnection.groupChatSocket.removeEventListener(
          "message",
          groupMessageCallback
        );
        socketConnection.groupChatSocket.removeEventListener("open", () => {
          setGroupConnected(false);
        });
      }
    };
  }, [socketConnection]);



  

  const privateMessageCallback = (event) => {
    // playNotificationSound(`/audio/tones.mp3`)
    
    const resp = JSON.parse(event.data);
    if (resp.message) {
      const privateChatQueryExists = queryClient.getQueryState(["user-conversation-chat", resp.conversation_id]);
      if (privateChatQueryExists) {     
        queryClient.setQueryData(
          ["user-conversation-chat", resp.conversation_id],
          (oldData) => {
            oldData.pages[0].data.push({
              author: resp?.sender,
              message: resp.message,
              createdAt: new Date().toISOString(),
              active: true,
              edited: false,
            });
            return oldData;
          }
        );
      } else {
        notification.info({
          message: `New Message Received from ${resp?.sender}`,
          description: resp.message,
        });
      }
      queryClient.invalidateQueries({queryKey:["users-conversation-list"]});
      queryClient.invalidateQueries({queryKey:["messages-count"]});
    }
  };

  const adminMessageCallback = (event) => {
    console.log("Message from server in Admin Socket", event.data);
    let resp = JSON.parse(event.data);
    if (resp.message) {

      if (resp.sender === "admin") {
     
        const supportChatQuery = queryClient.getQueryState(["support-chat"]);
        console.log(supportChatQuery)
        if (supportChatQuery.status ==="success") {
          queryClient.setQueryData(["support-chat"], (oldData) => {
            oldData?.pages[0]?.data.push({
              author: resp?.sender,
              author_is_admin: true,
              message: resp.message,
              createdAt: new Date().toISOString(),
              active: true,
              edited: false,
            });
            return oldData;
          });
        }
        queryClient.invalidateQueries({queryKey:["support-chat"]});
        dispatch(setSupportChatPopupOpen(true));

      } else {
        // if message is sent by client into admin chat
        const senderQueryExists = queryClient.getQueryState(["messages-list", resp?.sender]);

        console.log("Sender Query Exists", senderQueryExists);
        if (senderQueryExists) {
          queryClient.setQueryData(
            ["messages-list", resp?.sender],
            (oldData) => {
              oldData?.pages[0]?.data.push({
                author: resp?.sender,
                author_is_admin: resp.sender === "admin",
                message: resp.message,
                createdAt: new Date().toISOString(),
                active: true,
                edited: false,
              });
              return oldData;
            }
          );
        } else {
          
          notification.info({
            message: `New Message: ${resp?.sender}`,
            description: resp.message,
          });
        }
        queryClient.invalidateQueries({queryKey:["messages-list", resp?.sender]});

        queryClient.invalidateQueries({queryKey:["messages-count"]});
        queryClient.invalidateQueries({queryKey:["list-conversation"]});
      }
    }
  };

  const groupMessageCallback = (event) => {
    let resp = JSON.parse(event.data);
    let group_id = resp.group_id;

    if (user?.email !== resp.sender) {
      const state = queryClient.getQueryState([
        "group-conversation-chat",
        group_id,
      ]);

      if (state) {
        let group_id = resp.group_id;
        queryClient.setQueryData(
          ["group-conversation-chat", group_id],
          (oldData) => {
            oldData.pages[0].data.push({
              author: resp.sender,
              message: resp.message,
              createdAt: new Date().toISOString(),
              group_id: group_id,
              active: true,
              edited: false,
            });
            return oldData;
          }
        );
      } else {
        notification.info({
          message: `New Group Message Received from ${resp?.sender}`,
          description: resp.message,
        });
      }
      queryClient.invalidateQueries({queryKey:["group-conversation-list"]})
      queryClient.invalidateQueries({queryKey:["messages-count"]});
    }
  };

  const ws = {
    sendPrivateMessage,
    sendMessageAdmin,
    sendMessageGroup,
    private_socket_connected,
    group_socket_connected,
    admin_socket_connected
  };

  return (
    <WebSocketContext.Provider value={ws}>
      {contextHolder}
      {children}
    </WebSocketContext.Provider>
  );
};
