/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formMode: "add",
  formModalOpen: false,

};


const tasksSlice = createSlice({
  name: "tasksState",
  initialState,
  reducers: {

    setFormMode: (state, action) => {
      state.formMode = action.payload;
    },
    setFormModalOpen: (state, action) => {
      state.formModalOpen = action.payload;
    },
    handleFormModalCancel: (state, action) => {
      state.formMode = "add";
      state.formModalOpen = false;
    },
    handleFormModalCreate: (state, action) => {
      state.formMode = "add";
      state.formModalOpen = true;
    },
    handleFormModalEdit: (state, action) => {
      state.formMode = "edit";
      state.formModalOpen = true;
    },
  },
  extraReducers: () => {

  },
});

export const {
  setFormMode,
  setFormModalOpen,
  handleFormModalCancel,
  handleFormModalCreate,
  handleFormModalEdit,

} = tasksSlice.actions;
export default tasksSlice.reducer;
