import {  createSlice } from "@reduxjs/toolkit";

const initialState = {
  document: {
    open: false,
    mode: "ADD",
    bulk: false,
    item_id_to_attach: null,
    selected_document: null,
  },
  documentViewer: {
    open: false,
    type: "_id",
    selectedDocument: {},
    task_id : null
  },
  document_links: {
    open: false,
    mode: "ADD",
    bulk: false,
    payload : null,
  },
  reference_pointer_links: {
    open: false,
    mode: "ADD",
    bulk: false,
    item_id : null,
  },
  reference_document_links: {
    open: false,
    mode: "ADD",
    bulk: false,
    item_id : null,
  },
  cloud_templates: {
    open: false,
    mode: "ADD",
    props: {},
  },
  reference_pointer: {
    open: false,
    mode: "ADD",
    bulk: false,
    payload: null,
    item_id: null
  },
  cloud_reference_pointer: {
    open: false,
    mode: "ADD",
    bulk: false,
    payload: null,
    item_id: null
  },
  task_template: {
    open: false,
    mode: "ADD",
    props: {
      formSchema: {},
      formData: {},
      selected: null,
    },
  },
  generate_tasks: {
    open: false,
    mode: "ADD",
    props: {},
  },
  tasks: {
    open: false,
    mode: "ADD",
    payload: null,
    formInitialValues  : {
      
    }
  },
  entity: {
    open: false,
    mode: "ADD",
    payload: null,
  },
  control_outcome : {
    open: false,
    mode: "ADD",
    payload: null,
  }
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    toggleModal: (state, action) => {
      const {
        kind,
        ...otherState
      } = action.payload;

      state[kind] = {
        ...state[kind],
        ...otherState
      }
     
    
    },
    setDocumentIdToAttach: (state, action) => {
      state.document.item_id_to_attach = action.payload;
    },

    setDocumentFormData: (state, action) => {
      state.document.props.formData = action.payload;
    },
    toggleTaskTemplateModal: (state, action) => {
      const { mode = "ADD", open, selected = null } = action.payload;
      state.task_template.open = open;
      state.task_template.mode = mode;
      state.task_template.props.selected = selected;
    },
    setViewDocument: (state, action) => {
      const { open, selectedDocument = null, type,task_id } = action.payload;
      state.documentViewer.open = open;
      state.documentViewer.selectedDocument = selectedDocument;
      state.documentViewer.type = type;
      state.documentViewer.task_id = task_id;
    },
    setDocumentForEdit: (state, action) => {
      state.document.selected_document = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder;

  },
});

export const {
  toggleModal,
  setDocumentFormData,
  toggleTaskTemplateModal,
  setDocumentIdToAttach,
  setViewDocument,
  setDocumentForEdit,
} = modalsSlice.actions;
export default modalsSlice.reducer;