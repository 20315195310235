import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  
  viewTask: {
    open: false,
    payload: null,
  },
  documentVersions: {
    open: false,
    payload: null,
  },
  taskTimeline: {
    open: false,
    payload: null,
  },
  documentTimeline: {
    open: false,
    payload: null,
  },
  taskTemplate : {
    open: false,
    payload: null,
  },bulkUpdate : {
    open : false,
    selected : [],
  }
};

  const drawersSlice = createSlice({
    name: "drawers",
    initialState,
    reducers: {
      toggleDrawer: (state, action) => {
        const { kind, payload = null, open=false } = action.payload;
        state[kind].open = open;
        state[kind].payload = payload;
      },
      setBulkUpdateDrawerOpen : (state, action) => {
        if (action.payload==false){
          state.bulkUpdate.selected = [];
        }
      
        state.bulkUpdate.open = action.payload;
      },
      setBulkSelected : (state, action)=>{
        state.bulkUpdate.selected = action.payload
      }
    },
  });

export const { toggleDrawer,setBulkUpdateDrawerOpen,setBulkSelected } = drawersSlice.actions;
export default drawersSlice.reducer;
