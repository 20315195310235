/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { dayjs } from "utils/dayjs-extended";
import useAxios from "./useAxios";
import { createAvatar } from "@dicebear/core";
import { personas } from "@dicebear/collection";

export const useLoginMutation = () => {
  const api = useAxios();
  return useMutation({
    mutationFn: (data) => {
      return api.post("/auth/login", data);
    },
  });
};
export const useCollegueNamesQuery = (active = true) => {
  const api = useAxios();
  return useQuery({
    queryKey: ["list-collegue-names", active],
    queryFn: () => {
      return api.get(`/users/get_colleagues`, { params: { active: active } });
    },
  });
};

export const useEntityDocumentsQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["list-entities-documents"],
    queryFn: () => {
      return api.post("/documents/documents_for_items/", {
        item_query: {
          kind: {
            $in: ["manager", "shareclass", "fund", "subfund"],
          },
        },
        document_query: {},
      });
    },
    // staleTime: 0,
    // cacheTime: 0,
  });
};

export const useViewItemQuery = (item_id) => {
  const api = useAxios();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "local";

  return useQuery({
    queryKey: ["items", item_id, tab],
    queryFn: () => {
      let url = `/${tab === "cloud" ? "repo" : "items"
        }/item_data/${item_id}?output_format=flat`;
      return api.get(url);
    },
    enabled: !!item_id,
  });
};
export const useSingleItemViewQuery = (item_id) => {
  const api = useAxios();

  return useQuery({
    queryKey: ["items", item_id],
    queryFn: () => {
      let url = `/items/item_data/${item_id}?output_format=flat`;
      return api.get(url);
    },
    enabled: !!item_id,
  });
};
export const useGetComments = (item_id) => {
  const api = useAxios();
  return useQuery({
    queryKey: ["get_comments", item_id],
    queryFn: () => {
      return api.get(`/items/get_comments?item_id=${item_id}`);
    },
    enabled: !!item_id,
  });
};
export const useDeleteComments = () => {
  const api = useAxios();
  return useMutation({
    mutationFn: (_id) => api.delete(`/items/delete_comment?_id=${_id}`),
  });
};

export const useAddCommentMutation = () => {
  const api = useAxios();
  return useMutation({
    mutationFn: (newComment) => {
      return api.post("/items/add_comment", { ...newComment });
    },
  });
};
export const useEditCommentMutation = () => {
  const api = useAxios();
  return useMutation({
    mutationFn: (comment) => api.put(`/items/edit_comment`, comment),
  });
};
export const useItemsListQuery = (
  output_format = "flat",
  kind = "task",
  tab = null
) => {
  const api = useAxios();
  const [searchParams ] = useSearchParams();

  // const searchQuery = searchParams.get('search')
  const assignee = searchParams.get("assignee");
  const status = searchParams.get("status");
  const tags = searchParams.getAll("tags");
  const group = searchParams.get("parent_id");
  const due_date = searchParams.get("due_date");
  const priority = searchParams.get("priority");
  const fund_id = searchParams.get("fund_id");
  const from = searchParams.get("from");
  const to = searchParams.get("to");
  return useQuery({
    queryKey: [
      "items",
      kind,
      tab,
      status,
      assignee,
      tags,
      group,
      due_date,
      priority,
      fund_id,
      from,
      to,
      output_format,
    ],
    queryFn: () => {
      let params = {
        kind: kind,
        fund_id: fund_id,
        assignee: assignee,
        status: status,
        output_format: output_format,
        active: true,
        parent_id: group,
        due_date: due_date,
        priority: priority,
        from: from,
        to: to,
      };
      let body = {};
      if (tags.length > 0) {
        body.tags = { $all: tags };
      }
      if (kind == "task_template" && tab === "cloud") {
        body.client_conn = "config";
      }
      return api.post(`/items/query`, body, {
        params: params,
      });
    },
    enabled: !!output_format && !!kind,
  });
};

export const useFetchFormSchemaQuery = (kind, mode) => {
  const api = useAxios();
  let [searchParams, _] = useSearchParams();
  const tab = searchParams.get("tab");
  return useQuery({
    queryKey: ["fetch-form-schema", kind, mode, tab],
    queryFn: () => {
      return api.get(`/items/form/${kind}/${mode}`);
    },
    enabled: !!kind && !!mode,
  });
};
export const useFetchFormGridSchemaQuery = (kind, mode) => {
  const api = useAxios();
  return useQuery({
    queryKey: ["form_grid_query", kind, mode],
    queryFn: () => api.get(`/items/grid/${kind}/${mode}`),
  });
};

export const useEntityTreeQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["entity_tree_query"],
    queryFn: () => {
      return api.post(
        `/items/query?output_format=tree&kind=manager&active=true`,
        {
          projection: ["_id", "name", "kind"],
        }
      );
    },
  });
};

export const useGroupTreeQuery = () => {
  const api = useAxios();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  return useQuery({
    queryKey: ["group-tree-widget-query", tab],
    queryFn: () => {
      if (tab === "cloud") {
        return api.post(
          "/items/query?output_format=tree&kind=section&client_conn=config&active=true",
          {
            projection: ["_id", "name", "kind"],
            max_depth: 1,
          }
        );
      } else {
        return api.post(
          "/items/query?output_format=tree&kind=section&active=true",
          {
            projection: ["_id", "name", "kind"],
            max_depth: 1,
          }
        );
      }
    },
  });
};
export const useAdminCoversation = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["list-conversation"],
    queryFn: () => {
      return api.get(`/chat/admin/conversations`);
    },
  });
};

export const useTagsQuery = () => {
  const location = useLocation();
  const api = useAxios();
  const [searchParams, ] = useSearchParams();
  const tab = searchParams.get("tab");

  let kind = null;

  if (location.pathname === "/tasks") {
    kind = "task";
  } else if (location.pathname === "/templates" && tab === "cloud") {
    kind = "task_template";
  } else if (
    (location.pathname === "/documents" && tab === "null") ||
    (location.pathname === "/documents" && tab === "entities") ||
    (location.pathname === "/documents" && tab === "tasks")
  ) {
    kind = "plain_document";
  } else if (
    (location.pathname === "/documents" && tab === "references") ||
    tab === "cloud"
  ) {
    kind = "reference_document";
  } else if (
    location.pathname === "/documents" &&
    (tab === "reference_pointer" || tab === "cloud_reference_pointer")
  ) {
    kind = "reference_pointer";
  } else if (location.pathname === "/templates") {
    kind = "task_template";
  } else {
    kind = null;
  }

  return useQuery({
    queryKey: ["tags", kind, tab],
    queryFn: () => {
      let url;
      if (kind === "plain_document" || kind === "reference_document") {
        url = `/documents/list_uniques/tags/${kind}`;
      } else if (kind === "cloud") {
        url = `/documents/list_uniques/tags/${kind}?repo=True`;
      } else if (kind === "cloud_reference_pointer") {
        url = `/repo/list_uniques/tags/${kind}`;
      } else if (
        kind === "task" ||
        kind === "task_template" ||
        kind === "reference_pointer"
      ) {
        url = `/items/list_uniques/tags/${kind}`;
      }
      return api.get(url);
    },
  });
};

export const useNotificationsQuery = (page = null) => {
  const api = useAxios();
  return useQuery({
    queryKey: ["notifications", "unread", page],
    queryFn: () => {
      let url = null;
      if (page) {
        url = `/notifications/unread_messages?page=${page}`;
      } else {
        url = `/notifications/unread_messages`;
      }
      return api.post(url, {
        notification_group: "global_notifications",
        log_level: { $eq: "info" },
      });
    },
  });
};
export const useReadNotificationsQuery = (page = null) => {
  const api = useAxios();
  return useQuery({
    queryKey: ["notifications", "read", page],
    queryFn: () => {
      let url = null;
      if (page) {
        url = `/notifications/messages?page=${page}`;
      } else {
        url = `/notifications/messages`;
      }
      return api.post(url, {
        notification_group: "global_notifications",
        log_level: { $eq: "info" },
      });
    },
  });
};
export const usePatchItemMutation = () => {
  const api = useAxios();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const defaultTab = location.pathname === "/tasks" ? "all" : "local";
  const tab = searchParams.get("tab") || defaultTab;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      let url = `/${tab === "cloud" ? "repo" : "items"}/update`;
      return api.patch(url, data);
    },
    onSuccess: (response) => {
      if (response?.data?.success) {
        message.success(response?.data?.msg || "updated successfully");
        queryClient.invalidateQueries({ queryKey: ["items"] });
      } else {
        message.error(response?.data?.error || "Failed to delete");
      }
    },
    onError: (error) => {
      message.error(`Failed to update: ${error.message}`);
    },
  });
};

export const useCreateItemMutation = () => {
  const api = useAxios();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "local";
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      let url = `/${tab === "cloud" ? "repo" : "items"}/create`;
      return api.post(url, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["items"] });
    },
  });
};

export const useStatusChangeMutation = () => {
  const api = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ status, payload }) => {
      let url;
      if (status === "completed") {
        url = "/items/task/revert";
      } else {
        url = "/items/task/complete";
      }
      return api.patch(url, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["items"] });
    },
  });
};
export const useDeleteItemMutation = () => {
  const api = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (_id) => {
      return api.delete("/items/delete", {
        data: {
          _id: _id,
        },
      });
    },
    onSuccess: (response) => {
      if (response?.data?.success) {
        message.success(response?.data?.msg || "Deleted successfully");
        queryClient.invalidateQueries({ queryKey: ["items"] });
      } else {
        message.error(response?.data?.error || "Failed to delete");
      }

    },
    onError: (error) => {
      console.log(error)
      message.error(`Failed to delete: ${error?.message}`);
    },
  });
};

export const useDeleteTaskBulkMutation = () => {
  const api = useAxios();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  return useMutation({
    mutationFn: (_id_list) => {
      if (tab === "cloud") {
        return api.delete("/repo/delete_many", {
          data: {
            _id_list: _id_list,
          },
        });
      }
      return api.delete("/items/delete_many", {
        data: {
          _id_list: _id_list,
        },
      });
    },
    onSuccess: (response) => {
      if (response?.data?.success) {
        message.success(response?.data?.msg || "Deleted successfully");
        queryClient.invalidateQueries({ queryKey: ["items"] });
      } else {
        message.error(response?.data?.error || "Failed to delete");
      }
    },
    onError: (error) => {
      console.log(error)
      message.error(`Failed to delete: ${error?.error}`);
    },

  });
};


export const useDashboardQuery = () => {
  const api = useAxios();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  return useQuery({
    queryKey: ["dashboard_query", type],
    queryFn: () => {
      if (!type) {
        return api.get(`/items/dashboard/all`);
      }
      return api.get(`/items/dashboard/${type}`);
    },
  });
};

export const useDeleteMessageMutation = (type = "user") => {
  const api = useAxios();
  return useMutation({
    mutationFn: (message_id) => {
      return api.patch("/chat/message/delete", {
        message_id: message_id,
      });
    },
   
  });
};
export const useConversationUserList = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["users-conversation-list"],
    queryFn: async () => {
      const resp = await api.get(`/chat/user/conversations`);
      if (resp.data.success) {
        return resp?.data?.data;
      }
      return [];
    },
  });
};

export const useUserChatDropDownQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["user-chat-dropdown"],
    queryFn: async () => {
      return api.get(`/chat/get/users`);
    },
  });
};
export const useGroupConversationListQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["group-conversation-list"],
    queryFn: async () => {
      return api.get(`/chat/groups/info`);
    },
  });
};
export const useGroupInviteListQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["group-invite-list"],
    queryFn: async () => {
      return api.get(`/chat/groups/invites`);
    },
  });
};
export const useChatDropDownQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["user-chat-dropdown"],
    queryFn: async () => {
      return api.get(`/chat/get/users`);
    },
  });
};
export const useInviteGroupMutation = () => {
  const api = useAxios();
  return useMutation({
    mutationFn: (data) => {
      return api.post("/chat/group/send_invite", data);
    },
    onSuccess: (resp) => {
      if (resp.data.success) {
        message.success(resp.data.msg);
        // setIsModalOpen(false);
        // Modal.destroyAll();
      } else {
        message.warning(resp.data.msg);
        // setIsModalOpen(false);
        // Modal.destroyAll();
      }
      // queryClient.invalidateQueries(["group-conversation-chat"]);
    },
  });
};
export const useMessageCountQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["messages-count"],
    queryFn: async () => {
      return api.get(`/chat/all_unread_messages_count`);
    },
  });
};
export const useFundTreeQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["fundTree-widget-query"],
    queryFn: async () => {
      return api.get("/items/query?output_format=tree&kind=manager");
    },
  });
};
export const useReferencePointersListQuery = () => {
  const api = useAxios();
  const [searchParams] = useSearchParams();
  const reference_document = searchParams.get("reference_document");
  const tags = searchParams.getAll("tags");
  const tab = searchParams.get("tab");
  return useQuery({
    queryKey: ["list-reference-pointers", reference_document, tab, tags],
    queryFn: async () => {
      let params = {
        output_format: "parent",
        kind: "reference_pointer",
        // reference_document : reference_document,
        active: true,
      };
      if (reference_document !== "undefined") {
        params["reference_document"] = reference_document;
      }
      let body = {};

      if (tags.length > 0) {
        body.tags = { $all: tags };
      }

      if (tab == "cloud_reference_pointer" || tab == "cloud") {
        params.client_conn = "config";
      }

      return api.post(`/items/query`, body, {
        params: params,
      });
    },
  });
};
export const useUpcomingTasksListQuery = () => {
  const api = useAxios();
  const currentDate = dayjs().format("YYYY-MM-DD");
  const { user } = useSelector((state) => state.auth);
  const currentuser = user?._id;
  return useQuery({
    queryKey: ["items", "upcomming_tasks_query"],
    queryFn: async () => {
      return api.get(
        `/items/query?kind=task&assignee=${currentuser}&status=progress&output_format=flat&active=true&from=${currentDate}&to=null`
      );
    },
  });
};

export const useCompletedTasksListQuery = () => {
  const api = useAxios();
  const currentDate = dayjs().format("YYYY-MM-DD");
  const { user } = useSelector((state) => state.auth);
  const currentuser = user?._id;
  return useQuery({
    queryKey: ["items", "completed_tasks_query"],
    queryFn: async () => {
      return api.get(
        `/items/query?kind=task&assignee=${currentuser}&status=completed&output_format=flat&active=true&from=null&to=${currentDate}`
      );
    },
  });
};
export const useOverdueTasksListQuery = () => {
  const api = useAxios();
  const currentDate = dayjs().format("YYYY-MM-DD");
  const { user } = useSelector((state) => state.auth);
  const currentuser = user?._id;
  return useQuery({
    queryKey: ["items", "overdue_tasks_query"],
    queryFn: async () => {
      return api.get(
        `/items/query?kind=task&assignee=${currentuser}&status=progress&output_format=flat&active=true&from=null&to=${currentDate}`
      );
    },
  });
};
export const useClientListQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["list-client"],
    queryFn: async () => {
      return api.get("/clients/get_all");
    },
  });
};
export const useUserListQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["list-users"],
    queryFn: async () => {
      return api.get("/users/get_all?active=True");
    },
  });
};
export const useReferenceDocumentsListQuery = () => {
  const api = useAxios();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const location = useLocation();

  return useQuery({
    queryKey: ["list-reference-documents", tab, location.pathname], // Include `tab` in the query key
    queryFn: async () => {
      const url = "/documents/documents_for_kind";
      let queryParams = {
        kind: "reference_document",
      };

      if (
        (location.pathname === "/documents" &&
          tab === "cloud_reference_pointer") ||
        (location.pathname === "/templates" && tab === "cloud")
      ) {
        queryParams.repo = true;
      }

      return api.get(url, {
        params: queryParams,
      });
    },
  });
};
export const useDocumentDataQuery = (document_id) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const api = useAxios();

  return useQuery({
    queryKey: ["document-data", document_id],
    queryFn: async () => {
      let params = {
        active: true,
        _id: document_id,
      };

      if (tab === "cloud") {
        params.repo = true;
      }

      return api.get("/documents/query", {
        params: params,
      });
    },
    enabled: !!document_id, // Ensures the query runs only if `document_id` is truthy
  });
};

export const useItemFormDataQuery = (item_id) => {
  const api = useAxios();
  const [searchParams] = useSearchParams();
  var tab = searchParams.get("tab");
  return useQuery({
    queryKey: ["items", "formdata", item_id],
    queryFn: async () => {
      if (tab == "cloud_reference_pointer") {
        return api.get(
          `/repo/item_data_for_schema/${item_id}/update?out_format=flat`
        );
      }
      return api.get(
        `/items/item_data_for_schema/${item_id}/update?out_format=flat`
      );
    },
    enabled: !!item_id,
  });
};
export const useAllDocumentsForAttachmentsQuery = () => {
  const api = useAxios();
  return useQuery({
    queryKey: ["all-documents-for-attachments"],
    queryFn: async () => {
      let query = {
        item_query: {},
        document_query: {},
      };
      return api.post(`/documents/documents_for_items/`, query);
    },
  });
};

export const useDocumentVersionsQuery = (document_id) => {
  const [searchParams] = useSearchParams();
  var tab = searchParams.get("tab");
  const api = useAxios();
  return useQuery({
    queryKey: ["document-versions", document_id, tab],
    queryFn: () => {
      if (tab == "cloud") {
        return api.get(`/documents/versions/${document_id}?repo=True`);
      }
      return api.get(`/documents/versions/${document_id}`);
    },
    enabled: !!document_id,
  });
};
export const useItemTimelineQuery = (item_id) => {
  const [searchParams] = useSearchParams();
  var tab = searchParams.get("tab");
  const api = useAxios();
  return useQuery({
    queryKey: ["item-timeline", item_id, tab],
    queryFn: async () => {
      let url =
        tab == "cloud"
          ? `/notifications/messages?repo=True`
          : `/notifications/messages`;
      return api.post(url, {
        items_id: item_id,
      });
    },
    enabled: !!item_id,
  });
};
export const useDocumentsListQuery = (kind = null) => {
  const api = useAxios();
  const [searchParams] = useSearchParams();

  const tab = kind || searchParams.get("tab");
  const fund_id = searchParams.get("fund_id");
  const group = searchParams.get("parent_id");
  const assignee = searchParams.get("assignee");
  const tags = searchParams.getAll("tags");
  const due_date = searchParams.get("due_date");
  const status = searchParams.get("status");
  const priority = searchParams.get("priority");

  return useQuery({
    queryKey: [
      "fetch-documents",
      tab,
      fund_id,
      tags,
      assignee,
      group,
      due_date,
      priority,
      status,
    ],
    queryFn: async () => {
      let query = { item_query: {}, document_query: {} };
      let params = {};

      if (tags.length) params.tags = tags;

      switch (tab) {
        case "references":
          return api.get("documents/documents_for_kind", {
            params: { ...params, kind: "reference_document" },
          });

        case "cloud":
          return api.get("documents/documents_for_kind", {
            params: { ...params, kind: "reference_document", repo: true },
          });

        case "tasks":
          query.item_query = { kind: "task" };
          if (fund_id) query.item_query.fund_id = fund_id;
          if (assignee) query.item_query.assignee = assignee;
          if (group) query.item_query.parent_id = group;
          if (due_date) query.item_query.due_date = due_date;
          if (priority) query.item_query.priority = priority;
          if (status) query.item_query.status = status;
          break;

        case "entities":
          query.item_query.kind = { $in: ["manager", "shareclass", "fund", "subfund"] };
          if (fund_id) query.item_query._id = fund_id;
          break;

        default:
          if (fund_id) query.item_query.fund_id = fund_id;
          break;
      }

      if (tags.length) query.document_query.tags = { $all: tags };

      return api.post("/documents/documents_for_items/", query);
    },
  });
};

export const useDocumentLinksListQuery = (item_id) => {
  const api = useAxios();
  return useQuery({
    queryKey: ["list-task-document-links", item_id],
    queryFn: async () => {
      return api.get(
        `/documents/documents_for_item/${item_id}?key=document_links`
      );
    },
    enabled: !!item_id,
  });
};
export const useTaskDocumentQuery = (task_id) => {
  const api = useAxios();
  return useQuery({
    queryKey: ["task-documents", task_id],
    queryFn: async () => {
      return api.get(`/documents/documents_for_item/${task_id}`);
    },
    enabled: !!task_id,
  });
};
export const useTaskReferencePointersListQuery = (item_id) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "local";
  const api = useAxios();
  return useQuery({
    queryKey: ["list-reference-pointers-of-task", item_id, tab],
    queryFn: async () => {
      return api.get(
        `/${tab === "cloud" ? "repo" : "items"
        }/item_documents_data/${item_id}?collection=items&key=reference_pointers&active_item=true&active_documents=true`
      );
    },
    enabled: !!item_id,
  });
};
export const useUpdateManyMutation = () => {
  const api = useAxios();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "local";
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      let url = null
      let { is_list_field, ...payload } = data;
      if (is_list_field) {
        url = `/${tab === "cloud" ? "repo" : "items"}/update_list_many`;
      } else {
        url = `/${tab === "cloud" ? "repo" : "items"}/update_many`;
      }
      return api.patch(url, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["items"] });
      message.success("Updated successfully");
    },
    onError: (error) => {
      message.error(`Failed to update: ${error.message}`);
    },
  });
};
export const useUpdateBulkManyMutation = () => {
  const api = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => api.patch(`/items/update_list_many`, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["items"] });
      message.success("Updated successfully");
    },
    onError: (error) => {
      message.error(`Failed to update: ${error.message}`);
    },
  });
};

export const useAvatarQuery = (name) => {
  return useQuery({
    queryKey: ["avatar", name],
    queryFn: async () => {
      const avatarDataUri = await createAvatar(personas, {
        seed: name,
        radius: 50,
        backgroundColor: [
          "00897b",
          "00acc1",
          "039be5",
          "1e88e5",
          "3949ab",
          "43a047",
          "5e35b1",
          "7cb342",
          "8e24aa",
          "c0aede",
          "b6e3f4",
          "c0ca33",
          "d1d4f9",
          "d81b60",
          "e53935",
          "f4511e",
          "fb8c00",
          "fdd835",
          "ffb300",
          "ffd5dc",
          "ffdfbf",
        ],
      }).toDataUri();
      return avatarDataUri;
    },
    enabled: !!name,
  });
};

export const useUserDataQuery = (user_id, opened) => {
  const api = useAxios();
  return useQuery({
    queryKey: ["user-data", user_id, opened],
    queryFn: async () => {
      return api.get(`/users/user_data/${user_id}`);
    },
    enabled: opened,
  });
};
export const usePullDocMutation = () => {
  const api = useAxios();
  return useMutation({
    mutationFn: async (id_list) => {
      return api.post(`/repo/copy_documents`, {
        ids: id_list,
        copy_type: "from",
      });
    },
  });
};

export const usePullReferencePointer = () => {
  const api = useAxios();
  return useMutation({
    mutationFn: (id_list) => {
      return api.post(`/repo/pull_reference_pointer`, {
        id_list: id_list,
      });
    },
  });
};

export const useFetchRSS = (page_no = 1, search_query = null) => {
  const api = useAxios();
  const query = search_query === "null" ? null : search_query;
  
  return useQuery({
    queryKey: ["rss", page_no, query],
    queryFn: async () => {
      const params = {
        page: page_no,
      };
      if (query) {
       params.query = query;
      }
      let url = `/news/search`;
      const response = await api.get(url, { params });
      return response.data;
    }
  });
};

export const useFetchSchemaQuery = (key) => {
  const api = useAxios();
  return useQuery({
    queryKey: ["schema", key],
    queryFn: async () => {
      return api.get(`/items/schema/${key}`);
    },
    enabled: !!key,
  });
};
// export const useFetchRSS = (page_no = 1, search_query = null) => {
//   const api = useAxios();

//   return useQuery({
//     queryKey: ["rss", page_no, search_query],
//     queryFn: async () => {
//       const params = {
//         page: page_no,
//         query: search_query,
//       };
//       let url = `/news/search`;
//       const response = await api.get(url, { params });
//       return response.data;
//     }
//   });
// };

// export const useFetchRSS = (page_no = 1, page_size = 10, search_query = "") => {
//   const api = useAxios();
//   return useQuery({
//     queryKey: ["rss", page_no, page_size,search_query],
//     queryFn: async () => {

//       let url = null
//       if (search_query) {

//         url = `/news/search`
//         const response = await api.get(url);
//         return response.data;

//       } else {
//         const params = {
//           page: page_no,
//           page_size: page_size,
//         };
//         url = `/news/feed`
//         const response = await api.get(url, { params });
//         return response.data;

//       }
//     },
//   });
// };