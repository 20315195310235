/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Card, Input, Layout, Pagination, Spin } from "antd";
import { useFetchRSS } from "hooks/query-hooks";
import { useSearchParams, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { toggleModal } from "redux/features/modalsSlice";
import { useDispatch } from "react-redux";



const RSS = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search_query = searchParams.get("search") || null;
  const currentPage = parseInt(searchParams.get("page") || "1", 10);
  const pageSize = 10;
  const dispatch = useDispatch();

  const news_feed_query = useFetchRSS(currentPage, search_query);

  const handlePageChange = (page) => {
    const params = { page };
    if (search_query) {
      params.search = search_query;
    }
    setSearchParams(params);
  };

  const isLoading = news_feed_query?.isLoading;
  const articles = news_feed_query?.data?.articles || [];
  const totalPages = news_feed_query?.data?.total_pages || 0;
  const totalItems = totalPages * pageSize;

  const debouncedSearch = debounce((value) => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      if (value) {
        params.set("search", value);
      } else {
        params.delete("search");
      }
      return params;
    });
  }, 500);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    debouncedSearch(value);
  };

  return (
    <Layout className="p-3 mt-5 md:mt-0">
      <div className="flex justify-between items-center w-[100%] lg:w-[70%] m-auto">
        <h2 className="text-lg md:text-xl font-bold w-[100%] lg:w-[70%] m-auto">
          News Feed
        </h2>
        <Input
          onChange={handleSearchChange}
          defaultValue={search_query}
          placeholder="Search news..."
          className="w-[100%] lg:w-[30%] m-auto"
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center my-5 items-center h-screen">
          <Spin size="large" />
        </div>
      ) : articles?.length > 0 ? (
        articles.map((item, index) => {
          const [source, article] = item;
          if (!article) return null;
          return (
            <a key={index} href={article.link} target="_blank" rel="noreferrer">

            <Card              
              hoverable
              className="m-auto my-2 md:my-3 w-[100%] lg:w-[70%]"
              title={article.title}
              extra={<a href="#"
               onClick={() => {
                let formInitValuesTopass = {
                  name: article.title,
                  description: `<a href={${article?.link}}>{${article?.title}}</a>`
                };
                dispatch(
                  toggleModal({
                    open: true,
                    mode: "ADD",
                    kind: "tasks",
                    formInitialValues: formInitValuesTopass,
                  })
                );
              }}
              > Create Task</a>}
            >
            <p className="text-sm">Source: {source}</p>
              <p className="text-sm">{article?.published}</p>
              <p className="text-sm">{article?.summary}</p>
            </Card>

            </a>
          );
        })
      ) : (
        <div className="text-center my-5">No articles found</div>
      )}

      {totalPages > 1 && (
        <Pagination
          className="flex justify-center items-center my-4"
          current={currentPage}
          total={totalItems}
          pageSize={pageSize}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      )}
    </Layout>
  );
};

export default RSS;
