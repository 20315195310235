import dj from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dj.extend(customParseFormat)
dj.extend(advancedFormat)
dj.extend(weekday)
dj.extend(localeData)
dj.extend(weekOfYear)
dj.extend(weekYear)

export const dayjs = dj;